// Dependences
window.$ = window.jQuery = require('jquery');
require('popper.js');

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

//require('bootstrap/dist/js/bootstrap.min');
window.fitvids = require('fitvids');
require('bxslider/dist/jquery.bxslider.min');
require('@fancyapps/fancybox');
require('superfish');

const WOW = require('wowjs'); window.wow = new WOW.WOW({ live: false });
window.wow.init();

window.owlCarousel = require('owl.carousel');

require('./../../sitemap/ew-sitemap-generator');

require('./slide.js');


// Custom JS
require('./main.js');
