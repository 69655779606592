document.addEventListener('DOMContentLoaded', e => {
	executeSitemap();
});

const URL = window.location.protocol + "//" + window.location.host;

const readConfig = async () => {
	try {
		let response = await fetch(`${URL}/sitemap/ew-sitemap-config.json`);
		if (!response.ok) throw new Error(response.statusText);
		return await response.json();
	} catch (e) {
		console.error(e);
	}
}

const readSitemap = async () => {
	try {
		let response = await fetch(`${URL}/sitemap.xml`);
		if (!response.ok) throw new Error(response.statusText);
		return await response.text();
	} catch (e) {
		console.log(e);
	}
}

const callGenerateSitemap = async () => {
	try {
		let response = await fetch(`${URL}/sitemap/generate-sitemap.php`);
		if (!response.ok) throw new Error(response.statusText);
		return await response.json();
	} catch (e) {
		console.error(e);
	}
}

const executeSitemap = async () => {

	const config = await readConfig();

	if(!config){
		console.error("Sitemap Generator: No s'ha pogut llegir la configuració del sitemap");
		return;
	}

	if (!config.actiu) {
		console.log("Sitemap Generator: La generació del Sitemap no està activa");
		return;
	}

	const sitemap = await readSitemap();

	if (!sitemap) {
		callGenerateSitemap();
		console.log("Sitemap Generator: Generem el sitemap per primera vegada");
		return;
	}

	if (!config.periodicitat) {
		console.log("Sitemap Generator: No hi ha definida cap periodicitat per generar el Sitemap");
		console.log('Sitemap Generator: Tornem a generar el sitemap');
	}

	if (isNecessaryGenerateSitemapAgain(config)) {
		console.log('Sitemap Generator: Tornem a generar el sitemap');
		callGenerateSitemap();
	}

}

const isNecessaryGenerateSitemapAgain = function (config) {

	const lastUpdate = config.ultima_actualitzacio;
	const dateNow = new Date();
	let dateLastUpdate;

	if (lastUpdate && lastUpdate.length != 14) {
		console.log("Sitemap Generator: El format de la ultima actualització no és vàlid");
		console.log("Sitemap Generator: S'ha tornat a generar el sitemap");
		return false;
	}

	if (lastUpdate) {
		dateLastUpdate = new Date(
			lastUpdate.substring(0, 4),
			lastUpdate.substring(4, 6) - 1,
			lastUpdate.substring(6, 8),
			lastUpdate.substring(8, 10),
			lastUpdate.substring(10, 12),
			lastUpdate.substring(12, 14)
		)
	}

	const diffTime = Math.abs(dateNow - dateLastUpdate);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays > config.periodicitat) {
		return true;
	}

	//console.log("Sitemap Generator: No és necessari tornar a generar el sitemap");
	return false;
}
