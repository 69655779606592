const d = document;
const w = window;
let seleccio;

export default function imagesEqualHeight(selector) {
    seleccio = selector;
    resizeImages();
}

function resizeImages() {
    const items = d.querySelectorAll(seleccio);
    items.forEach((item) => {
        const cs = w.getComputedStyle(item);
        item.style.height = cs.width;
    });
}

w.addEventListener('resize', () => {
    resizeImages();
});