let myslider;
const slide = document.getElementById('slide');

const playSlideOnVideosEnd = function(){
    const videos = slide.querySelectorAll('video');
    videos.forEach(video => {
        video.addEventListener('ended', e => {
            video.currentTime = 0;
            myslider.goToNextSlide();
            myslider.startAuto();
        }, false);
    });
};

const resizeVideosSlide = function () {
    const videos = slide.querySelectorAll('video');
    const iframes = slide.querySelectorAll('iframe');

    if (videos || iframes) {
        const firstImg = slide.querySelector('img.img-to-get-size');

        if (videos) {
            videos.forEach(el => {
                el.width = firstImg.width;
                el.height = firstImg.height;
            });
        }
        if (iframes) {
            iframes.forEach(el => {
                el.width = firstImg.width;
                el.height = firstImg.height;
            });
        }
    }

};

const checkAndResetVideo = function($el){
    // Si el primer element és un video parem l'slide i resetegem el video
    const $video = $el.find('video');
    if ($video.length) {
        myslider.stopAuto();
        const video = $video[0];
        video.currentTime = 0;
        video.play();
    }
};

const showSlide = function () {
    if (!slide) return;

    let configBxSlider = {
        mode: $('.bxslider').data('efecte'),
        useCSS: true,
        autoHover: false,
        auto: true,
        adaptiveHeight: true,
        pause: $('.bxslider').data('pausa') * 1000,
        nextText: '<svg class="bi bi-chevron-left" width="50" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path fill-rule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z" clip-rule="evenodd"/>\n' +
            '</svg>',
        prevText: '<svg class="bi bi-chevron-left" width="50" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <path fill-rule="evenodd" d="M13.354 3.646a.5.5 0 010 .708L7.707 10l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clip-rule="evenodd"/>\n' +
            '</svg>',
        captions: true,
        autoControls: false,
        touchEnabled: false,
        onSlideBefore: function($el, oldIndex, newIndex){
            //Eliminem l'element i el clonem perquè torni a fer l'efecte amb el wow
            let node = document.querySelector('#htmlcaption' + newIndex);
            if(node){
                let newNode = node.cloneNode(true);
                node.insertAdjacentElement("beforebegin", newNode);
                node.remove();
            }
        },
        onSlideAfter: function($el, oldIndex, newIndex){
            checkAndResetVideo($el);
        }
    };

    // Si el primer element és un video, inicialitzem l'slide en "pause"
    const firstElementSlide = document.querySelector('.bxslider > div');
    const video = firstElementSlide.querySelector('video');
    if(video){
        configBxSlider.auto = false;
    }

    // Inicialitzem l'slider
    myslider = $('.bxslider').bxSlider(configBxSlider);

};


document.addEventListener('DOMContentLoaded', e => {
	if(!slide) return;

    playSlideOnVideosEnd();
    resizeVideosSlide();
    $(function(){
        //console.log('inicialitzem slide');
        showSlide();
    });
});

window.addEventListener('resize', e => {
	if(!slide) return;

    resizeVideosSlide();
});
